<template>
    <div class="container mt-2">
        <div class="card shadow-lg mt-5">
            <div class="card-body p-0">
                <div class="row justify-content-center m-0">
                    <div class="col-md-6 bg-danger-subtle rounded-start-2 align-content-center py-5 py-md-0">
                        <p class="text-center">
                        <span class="fa-stack fa-5x text-center">
                            <i class="fa-stack-1x fa-solid fa-signs-post text-light-emphasis"></i>
                            <i class="fa-stack-2x fa-regular fa-circle text-danger"></i>
                            <i class="fa-stack-1x fa-solid fa-question text-warning" style="font-size: 20%; position: relative; top: -32%;"></i>
                        </span>
                        </p>
                        <h1 class="text-center">
                            Oh no!
                        </h1>
                    </div>
                    <div class="col-md-6 justify-content-center align-content-center h-100">
                        <main class="w-100 m-auto p-2 d-flex justify-content-center align-items-center fw-bold  rounded-3 min-height-500">
                            <div class="w-75 py-5">
                                <h1 class="parent-text text-start align-items-center justify-content-center fw-bold fw-bolder pb-5 my-2">
                                    404 Not Found
                                </h1>

                                <div class="border border-2 border-danger-subtle rounded-3 p-3">
                                    <p class="text-center m-0">
                                        Sorry, we couldn't find the page you were looking for. Please check the URL in the address bar and try again.
                                    </p>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {},
}
</script>