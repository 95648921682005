import { render, staticRenderFns } from "./VinylRecord.vue?vue&type=template&id=6b459e95&scoped=true&"
import script from "./VinylRecord.vue?vue&type=script&lang=js&"
export * from "./VinylRecord.vue?vue&type=script&lang=js&"
import style0 from "./VinylRecord.vue?vue&type=style&index=0&id=6b459e95&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b459e95",
  null
  
)

export default component.exports